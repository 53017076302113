bcl.c.tripadvisorDetails = {
  props: {
    className: "c-tripadvisor-details",
    classNameGeneralSettings: "general-settings-JS",
    classNameIndividualReview: "individual-review-JS",
    classNameInput: "tripadvisorDetailsUrl-JS",
    classNameOpenModal: "open-modal-JS",
    classNameButtonModal: "button-modal-JS",
    classNameModal: "c-modal-inject",
    classNameWebFragment: "trip-advisor-web-fragment-JS",
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.tripadvisorDetails.init);
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document,
      $element = baseFind.getElementsByClassName(bcl.c.tripadvisorDetails.props.className);

    bcl.u.forEach($element, bcl.c.tripadvisorDetails.getRequest);
  },

  getRequest: function ($element) {
    if ($element.dataset.load) {
      return;
    } else {
      $element.dataset.load = "true";
    }

    $element.closest("div.generic-container")?.classList.add("hidden");
    const tabButtons = document.getElementsByClassName("c-stripemenu-wrapper-JS");
    const buttonOpinion = tabButtons[0].querySelector("button[data-anchor-ref=opiniones]");
    buttonOpinion?.classList?.add("hidden");
    const save = bcl.c.stripeMenu.props.listAnchor;
    bcl.c.stripeMenu.props.listAnchor = bcl.c.stripeMenu.props.listAnchor.filter((button) => {
      return button.node.dataset.anchorRef != "opiniones";
    });

    const $input = $element.getElementsByClassName(bcl.c.tripadvisorDetails.props.classNameInput)[0],
      urlTripAdvisor = $input.dataset.href;

    if (urlTripAdvisor) {
      bcl.ajax.getRequest(null, urlTripAdvisor, bcl.c.tripadvisorDetails.buildHtml, {
        $node: $element,
        $input: $input,
        $save: save,
      });
    }
  },

  buildHtml: function (response, obj) {
    if (!response || !response.response) {
      bcl.c.tripadvisorDetails.showWebFragment();
      return;
    }

    const data = JSON.parse(response.response);

    if (!data) {
      bcl.c.tripadvisorDetails.showWebFragment();
      return;
    }

    obj.data = data;

    bcl.c.stripeMenu.props.listAnchor = obj.$save;
    const tabButtons = document.getElementsByClassName("c-stripemenu-wrapper-JS");
    const buttonOpinion = tabButtons[0].querySelector("button[data-anchor-ref=opiniones]");
    buttonOpinion?.classList?.remove("hidden");
    obj.$node.closest("div.generic-container").classList.remove("hidden");

    bcl.c.tripadvisorDetails.buildGeneralSettings(obj);
    bcl.c.tripadvisorDetails.buildIndividualReview(obj);
  },

  buildGeneralSettings: function (obj) {
    const $node = obj.$node.getElementsByClassName(bcl.c.tripadvisorDetails.props.classNameGeneralSettings)[0];

    bcl.c.tripadvisorDetails.processDataInNode($node, obj.data);

    const $nodeChild = $node.querySelector("[data-attr]");
    if ($nodeChild.dataset.round) {
      obj.data.rating = Math.round(obj.data.rating);
    }

    if (obj.data.rating) {
      bcl.c.tripadvisorDetails.generateRatings(obj.data.rating, $node);
      bcl.u.removeClass($node, "hidden");
    } else {
      bcl.c.tripadvisorDetails.showWebFragment();
    }
  },

  buildIndividualReview: function (obj) {
    const $node = obj.$node.getElementsByClassName(bcl.c.tripadvisorDetails.props.classNameIndividualReview)[0],
      numberelements = obj.$input.dataset.numberelements,
      minimumRating = obj.$input.dataset.rating;

    if (!obj.data.reviews || !numberelements) {
      return;
    }

    const data = obj.data.reviews;

    if (minimumRating) {
      let count = 0;
      for (let i = 0; i < data.length; i++) {
        if (count == numberelements) {
          break;
        }
        if (data[i].rating < minimumRating) {
          data.splice(i, 1);
          i = i - 1;
        } else {
          count++;
        }
      }
    }

    for (let i = 0; i < numberelements; i++) {
      if (!data[i]) {
        continue;
      }

      if (i < numberelements - 1) {
        const $copy = $node.children[i].cloneNode(true);
        $node.appendChild($copy);
      }

      const item = data[i],
        $item = $node.children[i];

      bcl.c.tripadvisorDetails.processDataInNode($item, item);

      bcl.c.tripadvisorDetails.generateRatings(item.rating, $item, "c-tripadvisor");

      bcl.u.removeClass($item, "hidden");

      bcl.c.tripadvisorDetails.buildModalEvents(obj, $item, i);
    }

    bcl.u.removeClass($node, "hidden");
  },

  generateRatings: function (rating, $node, className) {
    if (!className) {
      className = "ta__dots";
    }

    rating = parseFloat(rating);

    let divRatingDots = $node.getElementsByClassName(className);

    if (divRatingDots && divRatingDots[0]) {
      divRatingDots = divRatingDots[0];

      for (let j = 0; j < 5; j++) {
        const nodeRating = document.createElement("div");
        bcl.u.addClass(nodeRating, "ta__dot");

        const $subNode = document.createElement("div");
        nodeRating.appendChild($subNode);

        bcl.u.updateClassByDotsScore($subNode, rating, j);

        divRatingDots.appendChild(nodeRating);
      }
    }
  },

  processDataInNode: function ($node, data, attr) {
    if (!attr) {
      attr = "data-attr";
    }

    const $nodes = $node.querySelectorAll("[" + attr + "]");
    bcl.u.forEach($nodes, function ($item) {
      let value = bcl.u.getInData(data, $item.getAttribute(attr));
      const method = "innerText";

      if (value) {
        if ($item.dataset.round) {
          value = Math.round(value);
        }

        if ($item.dataset.parsedate) {
          value = bcl.c.tripadvisorDetails.parseDate(value, $node);
        }

        $item[method] = value;
        bcl.u.removeClass($item, "hidden");
      } else {
        bcl.u.addClass($item, "hidden");
      }
    });
  },

  parseDate: function (value, $node) {
    if ((bcl.u.browser.IE() || bcl.u.mobile.iOS()) && value) {
      const dateStringSplit = value.split("T");
      value = dateStringSplit[0] + "T" + dateStringSplit[1].split("-")[0];
    }

    if (value.length > 0) {
      const date = new Date(value);
      return date.toLocaleDateString($node.dataset.locale, { year: "numeric", day: "numeric", month: "short" });
    }
    return "";
  },

  buildModalEvents: function (obj, $item, i) {
    const $button = $item.getElementsByClassName(bcl.c.tripadvisorDetails.props.classNameOpenModal)[0];

    $button.addEventListener("click", function () {
      bcl.c.tripadvisorDetails.clickModal(obj, $item, i);
    });
  },

  clickModal: function (obj, $item, i) {
    if (!bcl.c.tripadvisorDetails.props.$button) {
      bcl.c.tripadvisorDetails.props.$button = obj.$node.getElementsByClassName(bcl.c.tripadvisorDetails.props.classNameButtonModal)[0];
      bcl.c.tripadvisorDetails.props.$modal = obj.$node.getElementsByClassName(bcl.c.tripadvisorDetails.props.classNameModal)[0];
    }

    bcl.c.tripadvisorDetails.props.$button.dataset.href = "#modal-tripadvisor_" + i;

    bcl.c.tripadvisorDetails.cleanChildren(bcl.c.tripadvisorDetails.props.$modal);

    bcl.c.tripadvisorDetails.props.$modal.appendChild($item.cloneNode(true));

    bcl.u.triggerEvent(bcl.c.tripadvisorDetails.props.$button, "click");
  },

  cleanChildren: function ($element) {
    if ($element.children.length > 0) {
      $element.removeChild($element.children[0]);
    }
  },

  showWebFragment: function () {
    const $node = document.querySelector("." + bcl.c.tripadvisorDetails.props.classNameWebFragment);
    if ($node) {
      const $main = document.querySelector("." + bcl.c.tripadvisorDetails.props.className);
      $main.parentNode.removeChild($main);
      bcl.u.removeClass($node, "hidden");
      const text = document.querySelector("div[data-tripadvisor-error]").dataset.tripadvisorError;
      $node.closest(".c-container").querySelector(".cmp-title__text").textContent = text;
    }
  },
};

bcl.preInit.push(bcl.c.tripadvisorDetails.preInit);
