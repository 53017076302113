bcl.c.hotelRoomPage = {
  props: {
    $headRoom: null,
    firstLoad: true,
    orientativeMessage: ".c-header-detail__booking-claim",
    unavailableMessage: ".unavailable-content",
    price: ".c-header-detail__booking-price",
    headWrap: ".c-header-detail__booking-wrap",
  },

  initHeadRoomPage: function () {
    const $container = document.querySelector(".c-header-detail__booking");

    if (!$container) {
      return;
    }
    bcl.c.hotelRoomList.props.$headRoom = $container;
    bcl.u.addClass(bcl.c.hotelRoomList.props.$headRoom.children[1], "hidden");
  },

  loadDataInHeadHotelRoom: function () {
    if (!bcl.c.hotelRoomList.props.$headRoom) {
      return;
    }

    bcl.c.hotelRoomList.props.$headPrice = bcl.c.hotelRoomList.props.$headRoom.querySelector(bcl.c.hotelRoomList.props.priceSelector);

    if (bcl.c.hotelRoomList.props.price != 10000000000 && bcl.c.hotelRoomList.props.price != 0 && bcl.c.hotelRoomList.props.$headPrice) {
      bcl.c.hotelRoomList.props.$headPrice.dataset.marketPrice = bcl.c.hotelRoomList.props.price;
      bcl.c.hotelRoomList.props.$headPrice.innerText = bcl.s.currency.formatPrice(bcl.c.hotelRoomList.props.price);
      bcl.s.currency.convertPrices(bcl.c.hotelRoomList.props.$headRoom);
      bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.removeClassName);
    }
    bcl.u.removeSpinner(bcl.c.hotelRoomList.props.$headRoom);
  },

  updateUrl: function () {
    //TODO: REFACTOR
    bcl.c.hotelRoomPage.initHeadRoomPage();

    bcl.c.hotelRoomPage.showOrHideLayout(bcl.c.hotelRoomList.props.addClassName);

    bcl.c.hotelRoomList.initSelectors();

    const $el = document.querySelector("[data-async-load]");
    const oldUrl = $el.getAttribute("data-async-load");
    const oldUrlSelectors = oldUrl?.split(".");
    if (bcl.c.hotelRoomList.props.selectors) {
      let url = oldUrl.replace("json", bcl.c.hotelRoomList.props.selectors);
      if (url && oldUrlSelectors) {
        url = bcl.c.hotelRoomPage.replaceCurrencyUrl(url, oldUrlSelectors);
      }
      $el.setAttribute("data-async-load", url);
    }
    bcl.u.addSpinner($el);
    bcl.u.loadAsyncData();
    $el.setAttribute("data-async-load", oldUrl);
  },
  replaceCurrencyUrl: function (url, oldUrlSelectors) {
    if (bcl.s.currency?.props.marketRelativeRatesMap && bcl.s.currency?.props.currency.current) {
      const oldUrlCurrency = oldUrlSelectors[oldUrlSelectors.length - 2];
      if (oldUrlCurrency && Object.keys(bcl.s.currency.props.marketRelativeRatesMap).includes(oldUrlCurrency)) {
        const currentCurrency = bcl.s.currency.props.currency.current;
        if (oldUrlCurrency != currentCurrency) {
          return url.replace("." + oldUrlCurrency, "." + currentCurrency);
        }
      }
    }
    return url;
  },
  showOrHideLayout: function (method) {
    const $el = bcl.c.hotelRoomList.props.$headRoom;
    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "flex");
    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "justify-center");

    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.orientativeMessage), "hidden");
    bcl.u[method]($el.querySelector(bcl.c.hotelRoomPage.props.price), "hidden");

    bcl.u.addClass($el.querySelector(bcl.c.hotelRoomPage.props.unavailableMessage), "hidden");

    bcl.u[method]($el.querySelector(".booking-button-JS:not(.force-show-JS)"), "hidden");
  },

  showUnavailable: function ($el, firstMethod, secondaryMethod) {
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "flex");
    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.headWrap), "justify-center");

    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.orientativeMessage), "hidden");

    bcl.u[firstMethod]($el.querySelector(bcl.c.hotelRoomPage.props.price), "hidden");
    bcl.u[secondaryMethod]($el.querySelector(bcl.c.hotelRoomPage.props.unavailableMessage), "hidden");
    bcl.u[firstMethod]($el.querySelector(".booking-button-JS"), "hidden");
  },
};
