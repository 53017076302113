bcl.c.gallerySummary = {
  props: {
    className: "c-gallery-summary",
    classNameItem: "c-gallery-summary__item-content",
    classNameItemVideo: "c-video",
  },

  preInit: function () {
    bcl.u.docReady(bcl.c.gallerySummary.init);
  },

  init: function (baseDom) {
    if (baseDom) {
      if (bcl.u.containsClass(baseDom, bcl.c.gallerySummary.props.classNameItem)) {
        bcl.c.gallerySummary.checkIfIsVideo(baseDom);
      }
    }
  },

  checkIfIsVideo: function ($el) {
    const isVideo = $el.getElementsByClassName(bcl.c.gallerySummary.props.classNameItemVideo);

    if (isVideo && isVideo.length > 0) {
      bcl.u.addClass($el.parentNode, "c-gallery-summary__item--is-video");
    }
  },
};

bcl.preInit.push(bcl.c.gallerySummary.preInit);
