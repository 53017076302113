export const cssSelector = "cmp-bhg-carousel";

const carousel = {
  props: {
    cssSelector,
    cssSelectorSwiper: ".swiper-wrapper[data-items-load]",
    nameAttr: "data-items-load",

    config: {
      slidesPerView: 1,
      grabCursor: true,
      //centeredSlides: true,
      watchOverflow: true,
      observer: true,
      observeParents: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
        clickable: true,
      },
    },
  },

  init: function (baseDom) {
    const baseFind = baseDom ? baseDom : document;
    bcl.u.swiper.init(baseFind, this.props);
  },
};

export default carousel;
