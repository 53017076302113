bcl.c.hotelMap = {
  props: {
    nameLatAttr: "data-location-lat",
    nameLngAttr: "data-location-lng",
    containerMap: null,
    preview: ".map-preview-JS",
    $preview: null,
  },
  preInit: function () {
    bcl.u.docReady(bcl.c.hotelMap.initEvent);
    bcl.map && bcl.map.ready(bcl.c.hotelMap.init);
  },
  initEvent: function () {
    bcl.c.hotelMap.props.containerMap = bcl.map ? document.querySelector(bcl.map.getSelectorAttr("hotel-map")) : null;

    if (!bcl.c.hotelMap.props.containerMap) {
      return;
    }

    const $componentMap = bcl.c.hotelMap.props.containerMap.closest(".c-hotel-map");
    if ($componentMap) {
      const $preview = $componentMap.querySelector(bcl.c.hotelMap.props.preview);
      if ($preview) {
        bcl.c.hotelMap.generateImagePreview($preview);
        $preview.addEventListener("click", bcl.c.hotelMap.triggerInitMap);
      } else {
        const handleScroll = bcl.u.debounce(
          function () {
            if (bcl.u.isInViewport(bcl.c.hotelMap.props.containerMap)) {
              window.removeEventListener("scroll", handleScroll);
              bcl.c.hotelMap.triggerInitMap();
            }
          },
          250,
          null,
        );
        window.addEventListener("scroll", handleScroll);
      }
    }
  },
  init: function () {
    if (!bcl.c.hotelMap.props.containerMap) {
      bcl.u.removeBhgLoading(bcl.c.hotelMap.props.containerMap);
      return;
    }

    const lat = bcl.c.hotelMap.props.containerMap.getAttribute(bcl.c.hotelMap.props.nameLatAttr),
      lng = bcl.c.hotelMap.props.containerMap.getAttribute(bcl.c.hotelMap.props.nameLngAttr);
    let zoom = parseInt(bcl.c.hotelMap.props.containerMap.dataset.zoom);

    if (!lat || !lng) {
      bcl.u.removeBhgLoading(bcl.c.hotelMap.props.containerMap);
      return;
    }

    if (!zoom) {
      zoom = 12;
    }

    const point = new google.maps.LatLng(lat, lng);
    let centerMap = point;

    if (!bcl.u.mobile.isMobile()) {
      const degreesPerPixelX = 360 / Math.pow(2, zoom + 8);
      const offsetX = -window.innerWidth / 4;
      centerMap = new google.maps.LatLng(lat, degreesPerPixelX * offsetX + parseFloat(lng));
    }

    const map = new google.maps.Map(bcl.c.hotelMap.props.containerMap, {
      center: centerMap, // -0.1
      scrollwheel: false,
      zoom: zoom,
      fullscreenControl: false,
      minZoom: false,
      maxZoom: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      styles: bcl.map.props.mapStyles.defaultStyle,
    });

    new google.maps.Marker({
      map: map,
      position: point,
      icon: new google.maps.MarkerImage("/content/dam/barcelo/commons/icons/statics/poi_blue.svg", null, null, null, new google.maps.Size(48, 48)), // TODO: Add real icon
    });
    bcl.u.removeBhgLoading(bcl.c.hotelMap.props.containerMap);
  },

  triggerInitMap: function (e) {
    bcl.u.addBhgLoading(bcl.c.hotelMap.props.containerMap, true);
    bcl.map.init();

    if (e.target) {
      bcl.u.removeClass(bcl.c.hotelMap.props.containerMap, "hidden");
      e.target.parentNode.removeChild(e.target);
    }
  },

  generateImagePreview: function ($preview) {
    bcl.c.hotelMap.setImagePreview($preview);
    window.addEventListener("resize", function () {
      bcl.c.hotelMap.setImagePreview($preview);
    });
  },

  setImagePreview: function ($preview) {
    if (!$preview) {
      return;
    }
    if (bcl.u.mobile.isMobile()) {
      $preview.setAttribute("style", "background-image:url(" + $preview.dataset.imgmobile + ");");
    } else {
      $preview.setAttribute("style", "background-image:url(" + $preview.dataset.img + ");");
    }
  },
};

bcl.preInit.push(bcl.c.hotelMap.preInit);
